<template>
  <div class="game-panel">
    <div class="game-panel-inner">
      <div class="leaderboard">
        <div class="content-wrap">
          <div class="content">
            <div class="texts-wrap">
              <div class="logo">
                <img src="./../assets/logo.png" @click="store.commit('goTo','/')">
              </div>
              <span class="home-title-head font-ang">MINER'S RUSH</span>
              <span class="home-title font-ang">Play</span>
            </div>

            <div class="col-12 col-md-12 col-xs-12">
              <div class="panel">
                <div class="panel-top">
                </div>
                <div class="panel-middle">
                  <div class="panel-content">
                    <div class="col-np-12">
                      
                      <div class="col-np-12 col-np-md-12 col-np-xs-12">
                        <div class="beta-label">
                          <span class="beta-text font-ang">BETA</span>
                          <span class="play-version">1.1.9</span>
                        </div>
                      </div>
                      <div class="col-np-6 col-np-md-6 col-np-xs-12">
                        <div class="play-button-container-1">
                          <a href="https://play.google.com/store/apps/details?id=com.btc.dwarves" target="_blank">
                            <div class="play-button">
                              <span class="font-ang">DOWNLOAD</span>
                              <span class="play-version"><img src="@/assets/play/platform-android.png"> </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-np-6 col-np-md-6 col-np-xs-12">
                        <div class="play-button-container-1">
                          <a href="https://apps.apple.com/lv/app/miners-rush/id6499256659" target="_blank">
                            <div class="play-button">
                              <span class="font-ang">DOWNLOAD</span>
                              <span class="play-version"><img src="@/assets/play/platform-ios.png"> </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-np-3 col-np-md-3 col-np-xs-0"></div>
                      <div class="col-np-6 col-np-md-6 col-np-xs-12">
                        <div class="play-button-container-2">
                          <a href="https://play.minersrush.xyz/v119/" target="_blank">
                            <div class="play-button">
                              <span class="font-ang">PLAY</span>
                              <span class="play-version"><img src="@/assets/play/platform-webgl.png"></span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-np-3 col-np-md-3 col-np-xs-0"></div>
                    </div>
                  </div>
                </div>
                <div class="panel-bottom"></div>
              </div>

            </div>



          </div>
        </div>
        <div class="top-divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {registerUser} from "@/api";
import store from "@/store";

export default {
  name: 'Play',
  props: {

  },
  data() {
    return {
      store,
      
    }
  },
  methods: {
    async submit(){

    },

  },
  mounted() {
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.gray {
  filter: grayscale(100%);
}
.beta-label {
  width: 180px;
  z-index: 100;
  background: url('@/assets/play/Beta_back.png') no-repeat;
  background-size: 100% 100%;
  height: 200px;
  margin: 0px auto;
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: -100px;
  transform: scale(0.7);
}
.beta-label span {
  display: block;
  text-align: center;
}
.beta-text {
  font-size: 26px;
  margin: 70px 0 0 5px;
  transform: rotate(-10deg);
  color: #333;
}
.beta-label .play-version {
  color: #333;
  transform: rotate(-10deg);
  font-size: 20px;
  margin: -10px 0 0 15px;
}

.play-button-container-1, .play-button-container-2 {

}
.play-button {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 300px;
  height: 160px;
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
  margin: 0px auto;
}
.play-button-container-1 .play-button {
  width: 320px;
  background-image: url("./../assets/play/button_blue.png");
}
.play-button-container-2 .play-button {
  width: 270px;
  background-image: url("./../assets/play/button_green.png");
}

.play-button:hover {
  transform: scale(1.05);
}
.play-button:active {
  transform: scale(0.98);
}
.play-button span {
  display: inline-block;
  position: absolute;
  bottom: 55px;
  left: 55px;
  font-size: 26px;
  color: #fff;
  transition: transform 0.3s ease;
  text-shadow: 0px 4px #000;
}
.play-button-container-1 .play-button span {
  bottom: 35px;
  left: 75px;
}
.play-button-container-2 .play-button span {
  bottom: 30px;
  left: 95px;
}
.play-button span.play-version {
  font-size: 16px;
  color: #333;
  text-shadow: none;
  bottom: 0px;
  display: block;
  left: 0px;
  right: 0px;
  top: 0px;
}
.play-button-container-1 .play-button img, .play-button-container-2 .play-button img {
  position: absolute;
  width: 60px;
  top: 40px;
  left: 20px;
}
.play-button-container-2 .play-button img {
  left: auto;
  right: 0px;
  top: 85px;
}
.leaderboard {
  padding-top: 30px;
}
.home-title-head {
  display: block;
  margin-top: 20px;
}
.logo {
  float: left;
}
.logo img {
  width: 75px;
  height: 75px;
  margin-right: 20px;
}
.content-wrap {
  min-height: 450px;
}
.top-divider {
  margin-top: 0px;
}
p {
  color: #fff;
}
p span {
  display: inline-block;
  width: 150px;
}
h2 {
  font-size: 28px;
  color: #FFF500;
}

@media screen and (min-width: 500px) {
.beta-label {
  transform: scale(1);
  margin-top: -150px;
}
  .play-button {
    width: 330px;
    height: 180px;
    
  }
  .play-button-container-1 .play-button {
    width: 350px;
  }
  .play-button-container-2 .play-button {
    width: 300px;
  }
  .play-button span {
    font-size: 30px;
  }
}
</style>
