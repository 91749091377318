<template>
  <div class="tournament">
    <div class="panel" v-if="!loading">
      <div class="panel-header">
        <span class="font-ang">DAILY<br/>TOURNAMENT</span>
      </div>
      <div class="panel-content">
          <div class="pot-title font-ris">Todays pot:</div>
          <div class="pot-amount font-ang">$ 3860</div>
      </div>
      <div class="panel-footer">
        <div  class="font-ang panel-button" @click="play"><span>PLAY</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: 'Tournament',
  props: {

  },
  data() {
    return {
      username: "",
      password: "",
      passwordRepeat: "",
      loading: true
    }
  },
  methods: {
    play() {
      console.log();
    }
  },
  mounted() {
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.tournament {
  padding-top: 200px;
}
.panel {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 420px;
  width: 100%;
  max-width: 470px;
  position: relative;
  margin: 0px auto;
}
.panel-content {
  top: -30px;
  position: relative;
}
.panel-header {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 390px;
  height: 125px;
  margin:0px auto;
  text-align: center;
  position: relative;
  top: -30px;
  overflow: hidden;
}
.panel-header span {
  color: #FFF500;
  text-shadow: 1px 1px 0px #000;
  font-size: 32px;
  line-height: 36px;
  display: block;
  margin-top:25px;
}
.panel-footer {
  position: absolute;
  bottom: -50px;
  left: 0px;
  right:0px;
}
.panel-button {
  background: url("./../assets/play_button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width:340px;
  height: 180px;
  margin: 0px auto;
  position: relative;
  cursor: pointer;
  user-select: none;
  transform: scale(1);
  transition: transform 0.3s ease;
}
.panel-button:hover {
  transform: scale(1.05);
}
.panel-button:active {
  transform: scale(0.98);
}
.panel-button span {
  position: absolute;
  top: 80px;
  /* left: 30px; */
  width: 300px;
  font-size: 36px;
  color: #fff;
  text-shadow: 1px 1px 0px #000;
  text-align: center;
  transform: rotate(5deg);
}
.pot-title {
  text-align: center;
  color: #fff;
  font-size: 28px;
}
.pot-amount {
  background: linear-gradient(to bottom, #FFF500, #FFA800);
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 46px;
  line-height: 52px;
  //text-shadow: 0px 4px #000;
  text-outline: 2px #000;
}

</style>
