<template>
  <div class="game-panel">
    <div class="game-panel-inner">
      <div class="leaderboard">
        <div class="content-wrap">
          <div class="content">
            <div class="texts-wrap">
              <div class="logo">
                <img src="./../assets/logo.png" @click="store.commit('goTo','/')">
              </div>
              <span class="home-title-head font-ang">MINER'S RUSH</span>
              <span class="home-title font-ang">About Us</span>
            </div>

            <div class="col-12 col-md-12 col-xs-12">
              <div class="panel">
                <div class="panel-top">
                </div>
                <div class="panel-middle">
                  <div class="panel-content">
                    <div class="col-np-12">
                      

                      <p>We're an imaginative global gaming company on a mission to shake up the gaming scene using cutting-edge blockchain tech. Our goal? To create a wide range of awesome games, from laid-back puzzles to immersive MMO adventures, that go beyond the norm.<br><br>

                        Our team is a vibrant mix of talents from all walks of life, working together seamlessly to bring fresh ideas and skills to our games. We pool our knowledge and creativity to craft captivating gaming experiences that appeal to players everywhere.<br><br>

                        Always keeping an eye on the trends, we're all about using blockchain to give players more ownership, make games work together better, and build stronger communities. As we keep pushing forward with game development, our focus remains on delivering top-notch experiences and revolutionizing gaming with blockchain tech.</p>
                      <img class="about-img" src="@/assets/about.png">
                    </div>
                  </div>
                </div>
                <div class="panel-bottom"></div>
              </div>

            </div>



          </div>
        </div>
        <div class="top-divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {registerUser} from "@/api";
import store from "@/store";

export default {
  name: 'AboutUs',
  props: {

  },
  data() {
    return {
      store,
      
    }
  },
  methods: {
    async submit(){

    },

  },
  mounted() {
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.leaderboard {
  padding-top: 30px;
}
.home-title-head {
  display: block;
  margin-top: 20px;
}
.logo {
  float: left;
}
.logo img {
  width: 75px;
  height: 75px;
  margin-right: 20px;
}
.content-wrap {
  min-height: 450px;
}
.top-divider {
  margin-top: 0px;
}
p {
  color: #fff;
}
p span {
  display: inline-block;
  width: 150px;
}
h2 {
  font-size: 28px;
  color: #FFF500;
}

.pie1 {
  background: url("@/assets/misc/pie1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 360px;
  height: 360px;
}
.pie-descr {
  color: #eee;
}
.pie-descr span {
  text-align: center;
  display: block;
}
.about-img {
  display: block;
  margin: 20px -50px -50px -50px;
  width: calc(100% + 100px);
}

</style>
