<template>
  <transition
      name="fade-and-fly"
  >
    <div class="play" v-show="!loading && store.state.module.popup == 'play'">
      <div class="play-panel" >
        <div class="panel-header">
          <span class="font-ang">TOURNAMENT</span>
        </div>
        <div class="panel-content">
          
        </div>
        <div class="panel-footer">
          <div  class="font-ang panel-button" @click="submit"><span>START MATCH</span></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from "vue";
import {registerUser} from "@/api";
import store from "@/store";

export default {
  name: 'PlayPopup',
  props: {

  },
  data() {
    return {
      loading: true,
      store
    }
  },
  methods: {
    async submit(){
      /* const body = {
         email: "",
         username : this.username,
         password : this.password,
         confirmPassword : this.passwordRepeat
       };
       const response = registerUser(body);
       console.log(response);*/
      this.$router.push('/tournament' )
    },
    
  },
  mounted() {
    setTimeout(() => {
      //this.loading = false;
    }, 100);
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.play {
  position: absolute;
  top: 150px;
  left: calc(50% - 335px);
  /* right: 0px; */
  z-index: 150;
}
.play-panel {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 420px;
  margin: 0px auto;
  width: 670px;
  position: relative;
}
.panel-header {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 370px;
  height: 75px;
  margin:0px auto;
  text-align: center;
  position: relative;
  top: -20px;
}
.panel-header span {
  font-size: 28px;
  color: #fff;
  text-shadow: 1px 1px 0px #000;
  line-height: 75px;
}
.tab-panel span {

}
.tab-panel .tab-selected {

}
.tab-content {

}
.tab-title {

}

.panel-footer {
  position: absolute;
  bottom: -10px;
  left: 0px;
  right:0px;
}
.panel-button {
  background: url("./../assets/button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width:320px;
  height: 110px;
  margin: 0px auto;
  position: relative;
  cursor: pointer;
  user-select: none;
  transform: scale(1);
  transition: transform 0.3s ease;
}
.panel-button:hover {
  transform: scale(1.05);
}
.panel-button:active {
  transform: scale(0.98);
}
.panel-button span {
  position: absolute;
  top: 46px;
  /* left: 30px; */
  width: 340px;
  font-size: 28px;
  color: #fff;
  text-shadow: 1px 1px 0px #000;
  text-align: center;
}
.login-option-label {
  width: 70%;
  display: block;
  position: relative;
  margin: 0px auto;
  text-align: center;
  color: #ccc;
  font-size: 16px;
  user-select: none;
  margin-top: -15px;
}
.login-option-label span {
  color: #fff846;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
}
.fade-and-fly-enter-active,
.fade-and-fly-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}

.fade-and-fly-enter,
.fade-and-fly-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

.fade-and-fly-leave {
  opacity: 0;
  transform: translateY(-200px);
}

</style>
