<template>
  <div class="game-panel">
    <div class="game-panel-inner">
      <div class="leaderboard">
        <div class="content-wrap">
          <div class="content" style="min-height: 500px;">
            <div class="texts-wrap">
              <div class="logo">
                <img src="./../assets/logo.png" @click="store.commit('goTo','/')">
              </div>
              <span class="home-title-head font-ang">MINER'S RUSH</span>
              <span class="home-title font-ang">Leaderboard</span>
            </div>
            
            
            <div class="col-12 col-md-12 col-xs-12">
              <!--<div class="board-search">
                <input class="search-input" type="text" v-model="search" placeholder="Search player...">
                <img class="search-icon" src="@/assets/search-ico.png"/>
              </div>-->
              <div class="panel">
                <div class="panel-top">
                </div>
                <div class="panel-middle">
                  <div class="panel-content">

                    <div class="col-12 col-md-12 tops-wrap" v-if="!loading && gotData && search.length < 3 && currentPage == 0">
                      <div class="col-np-4 show-wide">
                        <div class="top-scores-block win2">
                          <img src="@/assets/2Cup.png"/>
                          <span class="win-name"><p>{{win2 ? win2.username : '-'}}</p></span>
                          <div class="score-panel">
                            <span class="win-score">Score: {{win2 ? win2.score : '-'}}</span>
                            <span class="win-prize font-ang">{{win2 ? win2.prize : '-'}}<span></span></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-np-4">
                        <div class="top-scores-block win1">
                          <img src="@/assets/1Cup.png"/>
                          <span class="win-name">{{win1 ? win1.username : '-'}}</span>
                          <div class="score-panel">
                            <span class="win-score">Score: {{win1 ? win1.score : '-'}}</span>
                            <span class="win-prize font-ang">{{win1 ? win1.prize : '-'}}<span></span></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-np-4 hide-wide">
                        <div class="top-scores-block win2">
                          <img src="@/assets/2Cup.png"/>
                          <span class="win-name"><p>{{win2 ? win2.username : '-'}}</p></span>
                          <div class="score-panel">
                            <span class="win-score">Score: {{win2 ? win2.score : '-'}}</span>
                            <span class="win-prize font-ang">{{win2 ? win2.prize : '-'}}<span></span></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-np-4">
                        <div class="top-scores-block win3">
                          <img src="@/assets/3Cup.png"/>
                          <span class="win-name">{{win3 ? win3.username : '-'}}</span>
                          <div class="score-panel">
                            <span class="win-score">Score: {{win3 ? win3.score : '-'}}</span>
                            <span class="win-prize font-ang">{{win3 ? win3.prize : '-'}}<span></span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="button-back" :class="prevId == null ? 'disabled' : ''" v-if="gotData" @click="getTournamentById(prevId)"></div>
                    <div class="button-forward" :class="nextId == null ? 'disabled' : ''" v-if="gotData" @click="getTournamentById(nextId)"></div>
                    <div class="tournament-date" v-if="gotData">Scores - Season 2 Day #{{tournamentNumber}}<br/><span>{{startDate}}</span></div>
                    <div class="table" :class="search.length > 2 || currentPage > 0 ? 'search-table' : ''">
                    <div class="table-row table-head" v-if="gotData">
                      <div class="table-cell">Rank</div>
                      <div class="table-cell">User</div>
                      <div class="table-cell">Score</div>
                      <div class="table-cell">Prize</div>
                    </div>
                      <span class="no-data" v-if="(!loading && !gotData) || allScores.length == 0 ">No Results</span>
                      <span>
                        <span  v-bind:key="i.rank" v-for="i in allScores">
                          <div class="table-row" v-if="i.rank > 0" :class="i.rank < 7 ? 'table-row-tops' : ''">
                              <div class="table-cell">{{i.rank}}</div>
                              <div class="table-cell" :title="i.user">{{ i.username }}</div>
                              <div class="table-cell">{{i.score}}</div>
                              <div class="table-cell">
                                <span v-if="i.rank >= 15">
                                {{i.prize && !i.nftWon ? i.prize :  !i.nftWon ? '-' : ''}}
                                </span>
                                <span v-if="i.nftWon" :class="'item-bg'" title="NFT Ticket"></span>
                                
                                <span v-if="tournamentNumber > 3">
                                  <span v-if="i.rank == 7 || i.rank == 8" :class="'boost1'" title="Miners Cart"></span>
                                  <span v-if="i.rank == 9 || i.rank == 10" :class="'boost2'" title="Rainbow Rock"></span>
                                  <span v-if="i.rank == 11 || i.rank == 12" :class="'boost3'" title="Pickaxe"></span>
                                  <span v-if="i.rank == 13 || i.rank == 14" :class="'boost4'" title="Shuffle"></span>
                                </span>
                                
                                
                                
                              </div>
                          </div>
                        </span>
                      </span>
                    </div>
                    
                  </div>
                </div>
                <div class="panel-bottom">
                  <div class="pagination" v-if="gotData">
                    <div class="pagination-back" @click="prevPage"></div>
                    <div class="pagination-pages">
                      <span class="page">{{currentPage + 1}}</span>
                      <!--<span class="page">2</span>
                      <span class="page">3</span>-->
                    </div>
                    <div class="pagination-forward" @click="nextPage"></div>
                  </div>
                </div>
              </div>
              
            </div>
            
            
            
          </div>
        </div>
        <div class="top-divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {getCurrentTournament, getTournament, getTournamentScores, registerUser} from "@/api";
import store from "@/store";
import {forEach} from "core-js/stable/dom-collections";
import {Web3} from "web3";

export default {
  name: 'logIn',
 props: {

  },
  data() {
    return {
      store,
      win1:  null,
      win2: null,
      win3: null,
      scores: [
        
      ],
      allScores: [],
      loading: true,
      gotData: false,
      search: '',
      itemsPerPage: 10,
      currentPage: 0,
      prevId: 0,
      nextId: 0,
      tournamentNumber : '',
      startDate: '',
      currId: -1,
      totalPages: 0
    }
  },
  computed: {
    filteredItems() {
      if (this.search.length > 2) {
        return this.allScores.filter(item => {
          return item.username.toLowerCase().includes(this.search.toLowerCase());
        });
      } else {
        return this.scores;
      }
    },
    /*totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },*/
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredItems.slice(startIndex, endIndex);
    }
  },
  methods: {
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.getTournamentScores();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getTournamentScores();
      }
    },
     async submit(){
       this.$router.push('/tournament' )
    },
    switchPanel() {
      this.tab = this.tab == 0 ? 1 : 0;
    },
    async getTournamentScores() {
      this.store.commit('setLoading', true);
      const resp = await getTournamentScores(this.currId, this.currentPage);
      this.setData(resp.data);
      this.store.commit('setLoading', false);
    },
    async getTournamentById(id, page){
      if(id == null)
        return;
      
      this.store.commit('setLoading', true);
      const resp = await getTournament(id);
      if(resp && resp.status == 200) {
        this.currentPage = 0;
        this.currId = resp.data.tournamentId;
        this.totalPages = Math.ceil(resp.data.scoreCount / 10);
        await this.getTournamentScores(page);
        this.prevId = resp.data.previousTournamentId;
        this.nextId = resp.data.nextTournamentId;
        this.tournamentNumber = resp.data.tournamentNumber;
        this.formatDate(resp.data.startDate);
      }
      this.store.commit('setLoading', false);
      this.loading = false;
      this.gotData = true;
    },
    formatDate(dateString) {
      const date = new Date(dateString);

// Extract the day, month, and year components
      const day = date.getDate().toString().padStart(2, '0'); // padStart ensures two digits
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();

// Concatenate the components in the desired format
      this.startDate = `${day}.${month}.${year}`;
    },
    setData(data) {
      let sc = [];
      this.win1 = null;
      this.win2 = null;
      this.win3 = null;
      this.allScores = [];
      let web3 = new Web3(window.ethereum);
      console.log('got data!!', data)
      for(let i = 0; i < data.length; i++) {
        let r = data[i];
        r.rank = (this.currentPage * 10) + 1 + i;
        if(r.prize != null && r.prize != 0) {
          r.prize = web3.utils.fromWei(r.prize, 'ether')
          r.prize = parseFloat(r.prize).toFixed(2);
        }
        //this.allScores.push(r);
        if(i ==0 && this.currentPage == 0)
          this.win1 = r;
        else if(i ==1 && this.currentPage == 0)
          this.win2 = r;
        else if(i == 2 && this.currentPage == 0)
          this.win3 = r
        else
          sc.push(r);
      }
      this.allScores = sc;
      console.log("allsoces???", this.allScores)
    }
  },
  
  async mounted() {
      this.store.commit('setLoading', true);
      const resp = await getCurrentTournament(null, null);
      this.store.commit('setLoading', false);
      if(resp && resp.status == 200) {
        
       //this.setData(resp.data.scores);
       this.prevId = resp.data.previousTournamentId;
       this.nextId = resp.data.nextTournamentId;
       this.getTournamentById(resp.data.tournamentId);
       //this.tournamentNumber = resp.data.tournamentNumber;
        //this.formatDate(resp.data.startDate);
      }
      
      this.loading = false;
      this.gotData = true;    
  }
}
</script>

<style lang="scss" scoped>
.win-prize span {
  width: 30px;
  height: 30px;
  background: url('@/assets/core.png') no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  margin: -5px 0 0px 5px;
}
.tournament-date span {
  font-size: 16px;
}
.disabled {
  opacity: 0.5;
}
.leaderboard {
  padding-top: 30px;
}
.home-title-head {
  display: block;
  margin-top: 20px;
}
.logo {
  float: left;
}
.logo img {
  width: 75px;
  height: 75px;
  margin-right: 20px;
}
.table {
  overflow: hidden;
  border-radius: 15px;
  margin-top: 20px;
  clear: both;
}

.table-row {
  width: 100%;
  height:35px;
  background: rgba(0,0,0,0.15);
  margin-bottom: 2px;
  display: block;
  position: relative;
  overflow: hidden;
}
.table-row.table-row-tops {
  //height: 45px;
}
.table-head {
  background: rgba(0,0,0,0.3);
}
.table-cell {
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 35px;
  float: left;
  font-size: 13px;
}
.table-row.table-row-tops .table-cell {
  //line-height: 45px;
}
.table-row.table-row-tops .table-cell:nth-child(2), .table-row.table-row-tops .table-cell:nth-child(3) {
  color: #FFF500FF;
  text-shadow: 1px 1px 0px #000;
}

.table-row .table-cell:nth-child(1) {
  width: 45px;
  border-right: 1px solid #000;
}
.table-row .table-cell:nth-child(2) {
  width: 50%;
  border-right: 1px solid #000;
  text-align: left;
  text-indent: 5px;
}
.table-row .table-cell:nth-child(3) {
  width: calc(40% - 45px - 3px);
}
.table-row .table-cell:nth-child(4) {
  width: 10%;
}
@media screen and ( min-width: 550px ) {
  .table-cell {
    font-size: 16px;
  }
  .table-row .table-cell:nth-child(4) {
    width: 15%;
  }
  .table-row .table-cell:nth-child(2) {
    text-indent: 20px;
  }
  .table-row .table-cell:nth-child(3) {
    width: calc(35% - 45px - 3px);
  }
}
@media screen and ( min-width: 767px ) {
  .table-row .table-cell:nth-child(4) {
    width: 20%;
  }
  .table-row .table-cell:nth-child(3) {
    width: calc(30% - 45px - 3px);
  }
}
.button-back, .button-forward {
  width: 46px;
  height: 46px;
  background-size: 100% 100%;
  position: absolute;
  z-index: 20;
  top: -33px;
  background-repeat: no-repeat;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease;
}
.button-back:hover, .button-forward:hover {
  transform: scale(1.05);
}
.button-back:active, .button-forward:active {
  transform: scale(0.95);
}
.button-back {
  background-image: url('./../assets/back.png');
  left: 0px;
}
.button-forward {
  background-image: url('./../assets/forward.png');
  right: 0px;
}
.pagination {
  z-index: 20;
  position: relative;
  text-align: center;
}
.pagination-back, .pagination-forward {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease;
  display: inline-block;
  width: 36px;
  height: 36px;
  margin: 12px 5px -12px 5px;
}
.pagination-back {
  background-image: url('./../assets/back.png');
}
.pagination-forward {
  background-image: url('./../assets/forward.png');
}
.pagination-back:hover, .pagination-forward:hover {
  transform: scale(1.05);
}
.pagination-back:active, .pagination-forward:active {
  transform: scale(0.95);
}
.pagination-pages {
  display: inline-block;
}
.pagination-pages span {
  display: inline-block;
}
.page {
  color: #fff;
  font-size: 20px;
  padding: 3px 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin: 0 10px;
  transform: rotateX(180deg);
}
.tournament-date {
  width: 340px;
  position: absolute;
  top: -33px;
  color: #fff;
  left: calc(50% - 170px);
  font-size: 16px;
  text-align: center;
}

.item-bg, .boost1, .boost2, .boost3, .boost4 {
  width: 32px;
  height: 32px;
  background: url("./../assets/items/1dayTicket.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: inline-block;
}
.boost1 {
  background: url("./../assets/items/BoosterMega.png") no-repeat;
  background-size: 100% 100%;
}
.boost2 {
  background: url("./../assets/items/BoosterJoker.png") no-repeat;
  background-size: 100% 100%;
}
.boost3 {
  background: url("./../assets/items/BoosterKiller.png") no-repeat;
  background-size: 100% 100%;
}
.boost4 {
  background: url("./../assets/items/BoosterShuffle.png") no-repeat;
  background-size: 100% 100%;
}
.top-scores-block {
  display: block;
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #666;
  clear: both;
}
.top-scores-block img {
  width: auto;
  margin: 0px auto;
  display: block;
  float: left;
}

.top-scores-block span {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center; /* Vertically center the content */
  //justify-content: center;
  padding-left: 10px;
}


.win-name {
  font-size: 18px;
  color: #fff;
  text-shadow: 1px 1px 0px #000;
  height: 50px;
  padding: 0 10px;
  width: calc(100% - 250px);
}
.win-name p {
  margin: 0px;
  padding: 0px;
}
.win-score {
  font-size: 16px;
  color: #fff;
  text-shadow: 1px 1px 0px #000;
  padding-top: 10px;
}
.win-prize {
  font-size: 26px;
  color: #FFF500FF;
  text-shadow: 1px 1px 0px #000;
  line-height: 40px;
  padding-bottom: 5px;
  justify-content: center;
}
.score-panel {
  /*background: url('@/assets/small-panel.png') no-repeat;
  background-size: 100% 100%;
  width: 140px;
  position: absolute;
  right: 0px;
  top: 14px;*/
  float: left;
}

.win1 {
}
.win2 {
}
.win3 {
}
.top-scores-block.win1 img {
  height: 100px;
}
.top-scores-block.win2 img {
  height: 100px;
}
.top-scores-block.win3 img {
  height: 100px;
}
.win2 .win-name {
  //margin: 70px 0 -70px;
}
.win1 .win-name {
  //margin: 40px 0 -20px;
}
.win3 .win-name {
  margin-top: -10px;
}
.tops-wrap {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 40px;
}
.show-wide {
  display: none;
}
.hide-wide {
  display: block;
}
.search-table {
  padding-top: 40px;
}

@media screen and ( min-width: 550px ) {
  .win-name {
    font-size: 20px;
  }
  .tournament-date {
    font-size: 22px;
  }
  .top-scores-block span {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center; /* Vertically center the content */
    //justify-content: center;
    padding-left: 10px;
    float: left;
  }

  .top-scores-block {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #666;
  }
  
  .win-score {
    justify-content: center;
  }
}
@media screen and ( min-width: 767px ) {
  .tournament-date {
    font-size: 26px;
  }
  .tops-wrap {
    margin-top: 30px;
    margin-bottom: 60px;
    padding-top: 0px;
  }
  .show-wide {
    display: block;
  }
  .hide-wide {
    display: none;
  }
  .win-name {
    height: 70px;
    width: auto;
    justify-content: center;
  }
  .top-scores-block img {
    width: 200px;
    margin: 0px auto;
    display: block;
    float: none;
  }
  .top-scores-block span {
    padding-left: 0px;
    float: none;
  }
  .top-scores-block {
    width: 100%;
    height: 340px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }
  .win-score {
    font-size: 20px;
    padding-top: 10px;
    justify-content: center;
  }
  .win-prize {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 5px;
    justify-content: center;
  }
  .score-panel {
    background: url('@/assets/small-panel.png') no-repeat;
    background-size: 100% 100%;
    width: 200px;
    position: absolute;
    bottom: -50px;
    left: calc(50% - 100px);
    right: auto;
    top: auto;
    float: none;
  }
  .win1 {
    background: url('@/assets/podium-mid.png') no-repeat;
    background-size: 100% 50%;
    background-position-y: bottom;
  }
  .win2 {
    background: url('@/assets/podium-left.png') no-repeat;
    background-size: 100% 42%;
    background-position-y: bottom;
  }
  .win3 {
    background: url('@/assets/podium-right.png') no-repeat;
    background-size: 100% 45%;
    background-position-y: bottom;
  }
  .top-scores-block.win1 img {
    height: 220px;
  }
  .top-scores-block.win2 img {
    height: 200px;
    padding-top: 50px;
  }
  .top-scores-block.win3 img {
    height: 180px;
    padding-top: 80px;
  }
  .win2 .win-name {
    //margin: 70px 0 -70px;
  }
  .win1 .win-name {
    //margin: 40px 0 -20px;
  }
  .win3 .win-name {
    margin-top: -10px;
  }
  .tops-wrap {
    margin-bottom: 60px;
  }
}
@media screen and ( min-width: 840px ) {
  .win-score {
    font-size: 20px;
  }
  .win-prize {
    font-size: 30px;
    line-height: 40px;
  }
  .score-panel {
    width: 230px;
    left: calc(50% - 115px);
  }
}
.no-data {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 20px;
  padding: 20px 0;
}
.board-search {
  margin: 0 0 -55px 0;
  clear: both;
  overflow: hidden;
}
.search-input {
  background: url('@/assets/search-bg.png') no-repeat;
  background-size: 100% 100%;
  border: none;
  background-color: transparent;
  width: 130px;
  height: 55px;
  line-height: 55px;
  padding: 0 35px 0 65px;
  color: #fff;
  font-size: 18px;
  outline:none;
  float: right;
  margin-right: 30px;
}
.search-icon {
  position: absolute;
  right: 60px;
  top: 18px;
}
::placeholder {
  color: #ccc;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #ccc;
}
</style>
