import { createApp } from 'vue';
import App from '@/App.vue';
import store from "@/store";
import router from "@/router";
import VueGtag from "vue-gtag";
var app = createApp(App);
app.use(router);
app.use(store);
app.use(VueGtag, {
    config: { id: "G-8P5JWV71QQ" }
});
app.mount('#app');
