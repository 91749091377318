<template>
  <div class="M_Home">
  <div class="game-panel">
    <div class="game-panel-inner">
      <div class="content-wrap">
        <div class="content">
              <div class="col-np-12">
                <div class="col-7 col-md-12 col-xs-12">
                  <HomeTexts></HomeTexts>
                </div>
                <div class="col-5">
                  <NotLoggedInPanel></NotLoggedInPanel>
                </div>
              </div>
              <div class="col-np-12 play-container">
                <div class="gold-row">
                  <div class="decor1"></div>
                  <div class="decor2"></div>
                  <div class="decor3"></div>
                </div>
                <div class="col-np-3 col-np-md-3 col-np-xs-0"></div>
                <div class="col-np-6 col-np-md-6 col-np-xs-12">
                  <div class="play-button-container">
                      <div class="play-button" @click="store.commit('goTo', '/play')">
                        <span class="font-ang">PLAY</span>
                      </div>
                   
                  </div>
                </div>
                <div class="col-np-3 col-np-md-3 col-np-xs-0"></div>
                <!--<div class="play-button-container-1">
                  <a href="https://www.minersrush.co/download/Miner'sRushM3-v0.9.9.6t.apk">
                    <div class="play-button">
                      <span class="font-ang">DOWNLOAD</span>
                      <span class="play-version"><img src="@/assets/play/platform-android.png"> </span>
                    </div>
                  </a>
                </div>
                <div class="beta-label">
                  <span class="beta-text font-ang">BETA</span>
                  <span class="play-version">0.9.9.6t</span>
                </div>
                <div class="play-button-container-2">
                  <a href="https://www.minersrush.co/static/v0.9.9.6t/" target="_blank">
                    <div class="play-button">
                      <span class="font-ang">PLAY</span>
                      <span class="play-version"><img src="@/assets/play/platform-webgl.png"></span>
                    </div>
                  </a>
                </div>-->
              </div>
        </div>
        <div class="unit"></div>
      </div>
    </div>
  </div>
      <div class="top-divider"></div>
      <div class="content">
      
      <MoneyBreakdown></MoneyBreakdown>
      </div>
    
    
</div>
</template>

<script>
import Vue from "vue";
import NotLoggedInPanel from "./../components/Home/NotLoggedInPanel.vue";
import HomeTexts from "@/components/Home/HomeTexts.vue";
import MoneyBreakdown from "@/components/Home/MoneyBreakdown.vue";
import store from "@/store";
import {getActiveDailyTournament} from "@/api";
export default {
  name: 'Home',
  components: {
    MoneyBreakdown,
    HomeTexts, 
    NotLoggedInPanel
  },
  props: {

  },
  data() {
    return {
      store,
      openedTab: 0,
      loading: true,
    }
  },
  methods: {
    login() {
      this.$store.commit('setPopup', 'login');
    }
  }
}
</script>

<style scoped>

.play-container {
  height: 150px;
  margin-top: 120px;
  margin-bottom: 30px;
}
.play-button-container-1, .play-button-container-2 {
  position: absolute;
  width: 330px;
  z-index: 100;
  bottom: 0px;
}
.play-button-container {
  width: 450px;
  margin: 0px auto;
  z-index: 100;
  position: relative;
}
.play-button-container-1 {
  width: 350px;
  left: calc(50% - 175px + 50px);
  bottom: 100px;
}
.play-button-container-2 {
  width: 300px;
  left: calc(50% - 150px + 50px);
  z-index: 90;
  bottom: 0px;
}
.beta-label {
  position: absolute;
  width: 180px;
  z-index: 100;
  bottom: 0px;
  left: calc(50% - 90px - 150px);
  background: url('@/assets/play/Beta_back.png') no-repeat;
  background-size: 100% 100%;
  height: 200px;
}
.beta-label span {
  display: block;
  text-align: center;
}
.beta-text {
  font-size: 26px;
  margin: 70px 0 0 5px;
  transform: rotate(-10deg);
  color: #333;
}
.beta-label .play-version {
  color: #333;
  transform: rotate(-10deg);
  font-size: 20px;
  margin: -10px 0 0 15px;
}
.play-button {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 330px;
  height: 180px;
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
  margin: 0px auto;
  cursor: pointer;
}
.play-button-container-1 .play-button, .play-button-container .play-button {
  width: 350px;
  background-image: url("./../assets/play/button_blue.png");
}
.play-button-container-2 .play-button  {
  width: 350px;
  background-image: url("./../assets/play/button_green.png");
}

.play-button:hover {
  transform: scale(1.05);
}
.play-button:active {
  transform: scale(0.98);
}
.play-button span {
  display: inline-block;
  position: absolute;
  bottom: 55px;
  left: 55px;
  font-size: 36px;
  color: #fff;
  transition: transform 0.3s ease;
  text-shadow: 0px 4px #000;
}
.play-button-container-1 .play-button span {
  bottom: 35px;
  left: 75px;
}
.play-button-container-2 .play-button span, .play-button-container .play-button span {
  bottom: 30px;
  left: 125px;
}
.play-button span.play-version {
  font-size: 16px;
  color: #333;
  text-shadow: none;
  bottom: 0px;
  display: block;
  left: 0px;
  right: 0px;
  top: 0px;
}
.play-button-container-1 .play-button img, .play-button-container-2 .play-button img, .play-button-container .play-button img {
  position: absolute;
  width: 60px;
  top: 40px;
  left: 20px;
}
.play-button-container-2 .play-button img, .play-button-container .play-button img {
  left: auto;
  right: 0px;
  top: 85px;
}
@media screen and ( min-width: 580px ) {
  .beta-label {
    left: calc(50% - 90px - 150px);
  }
  .play-button-container-1 {
    left: calc(50% - 175px + 100px);
  }
  .play-button-container-2 {
    left: calc(50% - 150px + 100px);
  }
}
@media screen and ( min-width: 767px ) {
  .play-container {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .play-button-container-1, .play-button-container-2 {
    bottom: 0px;
  }
  .play-button-container-1 {
    left: calc(50% - 175px - 200px);
  }
  .play-button-container-2 {
    left: calc(50% - 150px + 200px);
  }
  .beta-label {
    bottom: 0px;
    left: calc(50% - 90px);
  }
}
  @keyframes play-button {
  0% {transform: rotate(-5deg)}
  50% {transform: rotate(5deg)}
  100% {transform: rotate(-5deg)}
  
}
.gold-row {
  background: url('./../assets/gold-row.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 900px;
  height: 90px;
  position: absolute;
  z-index: 90;
  bottom: 0px;
  left: calc(50% - 450px);
}
.decor1 {
  background: url('./../assets/Creme2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  position: absolute;
  right: 170px;
  bottom: 15px;
  z-index: 100;
  transform: rotate(-3deg);
  transition: transform 0.3s ease;
  animation-name: decor1;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-backface-visibility: hidden;
}
@keyframes decor1 {
  0% {transform: rotate(3deg);}
  50% {transform: rotate(-3deg);}
  100% {transform: rotate(3deg);}
}
.decor2 {
  background: url('./../assets/BombYellow2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  position: absolute;
  left: 170px;
  bottom: 15px;
  z-index: 100;
  transform: rotate(-3deg);
  transition: transform 0.3s ease;
  animation-name: decor2;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-backface-visibility: hidden;
}
@keyframes decor2 {
  0% {transform: rotate(3deg);}
  50% {transform: rotate(-3deg);}
  100% {transform: rotate(3deg);}
}
.unit {
  background: url('./../assets/unit.png');
  background-size: 100% 100%;
  width: 300px;
  height: 600px;
  position: absolute;
  right: auto;
  left: 50%;
  bottom: 190px;
  z-index: 1;
}
@media screen and ( min-width: 767px ) { 
  .unit {
    right: calc(50% - 630px);
    left: auto;
    top: 150px;
  }
}
</style>
