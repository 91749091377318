import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-b3bbae9a"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "header" };
var _hoisted_2 = { class: "menu" };
var _hoisted_3 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", {
                class: _normalizeClass(["font-ris profile", [$data.router.currentRoute.path == '/profile' ? 'selected' : '', $data.store.getters.isLogged ? 'logged-in' : 'not-logged']]),
                onClick: _cache[1] || (_cache[1] = function ($event) { return ($data.store.getters.isLogged ? $data.store.commit('goTo', '/profile') : $options.login()); })
            }, [
                ($data.store.getters.isLogged)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($data.store.getters.getUser.username), 1))
                    : (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        onClick: _cache[0] || (_cache[0] = function ($event) { return ($options.login()); })
                    }, "Log In"))
            ], 2),
            _createElementVNode("span", {
                class: _normalizeClass(["font-ris", $data.router.currentRoute.path == '/' ? 'selected' : '']),
                onClick: _cache[2] || (_cache[2] = function ($event) { return ($data.router.push('/')); })
            }, "Home", 2),
            _createElementVNode("span", {
                class: _normalizeClass(["font-ris", $data.router.currentRoute.path == '/leaderboard' ? 'selected' : '']),
                onClick: _cache[3] || (_cache[3] = function ($event) { return ($data.router.push('/leaderboard')); })
            }, "Leaderboards", 2),
            _createElementVNode("span", {
                class: _normalizeClass(["font-ris", $data.router.currentRoute.path == '/shop' ? 'selected' : '']),
                onClick: _cache[4] || (_cache[4] = function ($event) { return ($data.router.push('/shop')); })
            }, "Shop", 2)
        ])
    ]));
}
