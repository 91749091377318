<template>
  <div class="money-breakdown">
    <div class="col-np-12 col-md-12">
      <div class="header">
        <span class="font-ang">ABOUT THE GAME</span>
      </div>
    </div>
    <div class="col-12 col-md-12 col-xs-12">
      <p>Miner's Rush is a classic Match-3 game with a twist!</p>

      <p>Play our free campaign mode to hone your skills, or compete against other players for a blockchain-powered Daily Tournament for at least $100! What happens if there's a mining rush? The prize pool goes up!</p>

      <p>Every play in the Daily Tournament requires 1 NFT ticket. Burn your ticket, enter the tournament, and try to get the highest score! Once the day is over, the scores are tallied, and the leaderboard is announced.</p>

      <iframe width="100%" height="520" src="https://www.youtube.com/embed/HfjaBiVRlvI?si=nmud-psPktMyY0WH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowfullscreen></iframe>
      <p>The top 6 players on the leaderboard win prizes.</p>

      <p>Then, it's time to blow the whistle, shut down the mines, and reset IMMEDIATELY for a brand new Daily Tournament!</p>
      <p>To see how the prize pool works, click <span class=" here-btn font-ang"  @click="store.commit('goTo','/prizes')">here</span></p>
    </div>
    
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
export default {
  name: 'MoneyBreakdown',
  components: {
    
  },
  props: {
    
  },
  data() {
    return {
      store
    }
  },
  methods: {

  },
  mounted() {

  },
  beforeUnmount() {

  }
}
</script>

<style lang="scss" scoped>p {
  color: #fff;
  text-shadow: 1px 1px 0px #000;
}
.pie1 {
  
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 360px;
  height: 360px;
}
.header {
  position: relative;
  margin: 0px auto 10px;
  display: block;
}
.header span {
  color: #FFF500;
  text-shadow: 1px 1px 0px #000;
  font-size: 32px;
  line-height: 36px;
  display: block;
  margin-top:25px;
  text-align: center;
}
.pie-descr {
  color: #eee;
}
.pie-descr span {
  text-align: center;
  display: block;
}
.here-btn {
  color: #FFF500;
  text-decoration: underline;
  cursor: pointer;
}
</style>
