<template>
  <div class="login">
    <div class="login-panel" v-if="!loading">
      <div class="panel-header">
        <span class="font-ang">LOGIN</span>
      </div>
      <div class="panel-content">
        <div class="tab-login" v-if="tab == 0">
          <form @submit.prevent>
            <input v-model="username" type="text" placeholder="Username..." class="panel-input"/>
            <input v-model="password" type="text" placeholder="Password..." class="panel-input"/>
            <input type="submit" name="CONTINUE" class="fake-submit" @click="submit"/>
            <span class="login-option-label font-ris">Dont have an account? <span class="option-label-button font-ris" @click="switchPanel">REGISTER</span></span>
          </form>
        </div>
        <div class="tab-register" v-if="tab == 1">
          <form @submit.prevent>
            <input v-model="username" type="text" placeholder="Username..." class="panel-input"/>
            <input v-model="password" type="text" placeholder="Password..." class="panel-input"/>
            <input v-model="passwordRepeat" type="text" placeholder="Password repeat..." class="panel-input"/>
            <input type="submit" name="CONTINUE" class="fake-submit" @click="submit"/>
            <span class="login-option-label font-ris">Have an account? <span class="option-label-button font-ris" @click="switchPanel">LOGIN</span></span>
          </form>          
        </div>
      </div>
      <div class="panel-footer">
        <div  class="font-ang panel-button" @click="submit"><span>CONTINUE</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {registerUser} from "@/api";

export default {
  name: 'logIn',
 props: {

  },
  data() {
    return {
      tab: 0,
      username: "",
      password: "",
      passwordRepeat: "",
      loading: true
    }
  },
  methods: {
     async submit(){
       this.$router.push('/tournament' )
     },
    switchPanel() {
      this.tab = this.tab == 0 ? 1 : 0;
    }
  },
  mounted() {
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.login {
  padding-top: 200px;
}
.login-panel {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 420px;
  margin: 0px auto;
  width: 100%;
  max-width: 470px;
  position: relative;
}
.panel-header {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 270px;
  height: 75px;
  margin:0px auto;
  text-align: center;
  position: relative;
  top: -20px;
}
.panel-header span {
  font-size: 28px;
  color: #fff;
  text-shadow: 1px 1px 0px #000;
  line-height: 75px;
}
.tab-panel span {
  
}
.tab-panel .tab-selected {
  
}
.tab-content {
  
}
.tab-title {
  
}

.panel-input {
  width: 60%;
  margin: 0px auto 30px;
  display: block;
  height: 50px;
  border-radius: 15px;
  outline: navajowhite;
  border: navajowhite;
  box-shadow: 1px 1px 0px #666, 1px 1px 0px inset #000;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 0 20px;
}
::placeholder {
  color: #999;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #999;
}
.fake-submit {
  position: absolute;
  left: -9999px;
}
.panel-footer {
  position: absolute;
  bottom: -10px;
  left: 0px;
  right:0px;
}
.panel-button {
  background: url("./../assets/button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width:280px;
  height: 100px;
  margin: 0px auto;
  position: relative;
  cursor: pointer;
  user-select: none;
  transform: scale(1);
  transition: transform 0.3s ease;
}
.panel-button:hover {
  transform: scale(1.05);
}
.panel-button:active {
  transform: scale(0.98);
}
.panel-button span {
  position: absolute;
  top: 38px;
  /* left: 30px; */
  width: 300px;
  font-size: 28px;
  color: #fff;
  text-shadow: 1px 1px 0px #000;
  text-align: center;
}
.login-option-label {
  width: 70%;
  display: block;
  position: relative;
  margin: 0px auto;
  text-align: center;
  color: #ccc;
  font-size: 16px;
  user-select: none;
  margin-top: -15px;
}
.login-option-label span {
  color: #fff846;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
}

</style>
