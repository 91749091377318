<template>
  <div class="HomeTexts">
    <div class="texts-wrap">
      <div class="logo">
        <img src="./../../assets/logo.png" @click="router.push('/')">
      </div>
      <span class="home-title-head font-ang">Dwarven Mining Co. Presents</span>
      <span class="home-title font-ang">MINER'S RUSH</span>
    </div>

    <div class="panel with-header" v-if="!loading">
      <div class="panel-top">
        <div class="panel-header multiline">
          <span class="font-ang">DAILY<br/>TOURNAMENT</span>
        </div>
      </div>
      <div class="panel-middle">
        <div class="panel-content">
          <div class="pot-title font-ris">Today's Jackpot</div>
          <div class="pot-amount font-ang">{{tournamentPrize}} CORE</div>
          
          <div class="pot-deco deco-left"></div>
          <div class="pot-deco deco-right"></div>
        </div>
      </div>
      <div class="panel-bottom">
        
        <div class="timer">{{time}}</div>
        <div class="timer-label">Remaining tournament time:</div>
      </div>
    </div>
    <span class="home-subtitle font-ris">
      <p>Miners Rush is a match-3 game built on CoreDAO blockchain! Match gems, complete challenges, and play in brand-new levels in our daily tournaments for HUUUUGE prizes!</p>
    </span>
    
  </div>
</template>

<script>
import Vue from "vue";
import router from "@/router";
import {getActiveDailyTournament, getCurrentTournament, getNextTournament} from "@/api";
import {Web3} from "web3";

export default {
  name: 'HomeTexts',
  props: {

  },
  data() {
    return {
      loading: false,
      router,
      tournamentPrize: '-',
      tournamentEnd: null,
      endDate: '',
      interval : null,
      time: '-',
      
      currentEnded: false,
      nextKnown: false
    }
  },
  methods: {
    async updateTimer() {
      if(this.endDate == '')
        return;
      // Get the current time in the user's local time zone
      const currentTime = new Date();

      const targetDateTime = new Date(this.endDate);
      
      // Calculate the time difference in milliseconds
      const timeDifference = targetDateTime.getTime() - currentTime.getTime();

      // Check if the target time has passed
      if (timeDifference <= 0 && this.interval) {
        clearInterval(this.interval); // Stop the timer
        console.log("Countdown complete!"); // Or update UI accordingly
        if(!this.currentEnded) {
          this.currentEnded = true;
        }
        if(this.currentEnded && !this.nextKnown) {
          const resp = await getNextTournament(null, null);
          if(resp && resp.status == 200) {
            this.endDate = resp.data.nextTournamentStartTime;
            this.nextKnown = true;
            return;
          }
        }
        if(this.nextKnown && this.currentEnded) {
          const resp = await getCurrentTournament(null, null);
          if(resp && resp.status == 200) {
            this.tournamentPrize = resp.data.prizePool;
            this.endDate = resp.data.endDate;
          }
        }
        return;
      }

      // Convert milliseconds to hours, minutes, and seconds
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // Format the remaining time
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      // Update the timer display (replace this with your own logic)
      this.time = formattedTime;
      //console.log(formattedTime);
    }
  },
  async mounted() {
    const resp = await getCurrentTournament(null, null);
    if(resp && resp.status == 200) {
      let web3 = new Web3(window.ethereum);
      if(resp.data.prizePool != null)
        this.tournamentPrize = web3.utils.fromWei(resp.data.prizePool, 'ether');
      this.endDate = resp.data.endDate;
      
      this.interval = setInterval(() => {
        this.updateTimer();
      }, 1000);
    }
  },
  beforeUnmount() {
    if(this.interval)
      clearInterval(this.interval);
  }
}
</script>

<style lang="scss" scoped>
.timer-label {
  color: #fff;
  text-align: center;
  transform: scaleY(-1);
  margin: 40px 0 40px 0;
  position: relative;
  display: block;
}
.timer {
  color: #fff;
  text-align: center;
  z-index: 20;
  position: relative;
  font-size: 20px;
  transform: scaleY(-1);
  background: url('@/assets/btn.png') no-repeat;
  background-position: center center;
  padding: 7px 0;
  background-size: 150px 37px;
  margin: 0px 0 -20px 0;
}

.texts-wrap {
  margin-bottom: 80px;
}
.home-title-head {
  display: block;
  margin-top: 20px;
  font-size: 15px;
}
@media screen and ( min-width: 600px ) {
  .home-title-head { 
    font-size: 16px;
  }
  .timer-label {
    margin: 20px 0 40px 0;
  }
}
.panel .panel-header span {
  line-height: 35px;
  padding-top: 5px;
}
.pot-title {
  text-align: center;
  color: #fff;
  font-size: 28px;
}
.pot-amount {
  background: linear-gradient(to bottom, #FFF500, #FFA800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 56px;
  line-height: 62px;
  filter: drop-shadow(4px 3px 0px rgba(0, 0, 0, 1));
  margin-bottom: 20px;
}
.home-subtitle p {
  font-size: 20px;
  text-shadow: 1px 1px 0px #fff;
}
.logo {
  float: left;
}
.logo img {
  width: 130px;
  height: 130px;
  margin-right: 20px;
}
.pot-deco {
  position: absolute;
  z-index: 25;
  background-size: 100%;
  background-repeat: no-repeat;
}
.deco-left {
  background-image: url("./../../assets/pot-deco-left.png");
  bottom: -85px;
  left: -30px;
  width: 108px;
  height: 97px;
}
.deco-right {
  background-image: url("./../../assets/pot-deco-right.png");
  bottom: -80px;
  right: -25px;
  width: 114px;
  height: 92px;
}
.HomeTexts {
  margin-top: 50px;
}
@media screen and ( min-width: 550px ) {
  .HomeTexts {
    margin-top: 50px;
  }
  .deco-left {
    bottom: -85px;
    left: -30px;
    width: 148px;
    height: 117px;
  }
  .deco-right {
    bottom: -80px;
    right: -25px;
    width: 144px;
    height: 112px;
  }
}
@media screen and ( min-width: 767px ) {
.HomeTexts {
  margin-top: 50px;
}
}
</style>
